.App {
  text-align: center;
  top:0;
  position: absolute;
  width: 100%;
}

.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 500px) {
  body {
    font-size: 70%;
  }
  .App-header {
    margin-top: 1.5em;
  }
}@media screen and (max-width: 300px) {
  body {
    font-size: 50%;
  }
  .App-header {
    margin-top: 1em;
  }
}
html, body {

  height: 100vh;
}
#root {
  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 4em;
  text-shadow: #8c0118  2px 2px 0px;
  color: white;
  padding-top: 70px;
}

.modal-dialog {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App-link {
  color: #61dafb;
}

.pop {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.countdown {
  
  margin-bottom: 22vh;
  font-size: 40%;
  background: #ffffff;
  border-radius: 30px;
  text-transform: capitalize;
  padding: 30px 40px 20px;
  color: #000367;
  text-shadow: none;
  box-shadow: #8c0118  5px 5px 0px;
}

.social {
  position:absolute;
  right:20px;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  top: 20px;
}

.social a {
display: block;
padding: 7px;
background: #C1C1FF;
color: white;
border-radius: 50%;
height: 40px;
width: 40px;
margin-left: 10px;
font-size: 20px;
}

body{
  background: #8c0118;
}

.link {
  color: #000367;
  text-decoration: none;;
}

.link:hover {
  color: rgb(0, 0, 0);
}

.social a:hover {
  background: #7C7CA3;
  position: relative;
  top: 2px;
  box-shadow: #8c0118  2px 2px 0px;
}
*, ::after, ::before {
  box-sizing: initial;
}

.pop {
  padding:0;
}

.pop:hover {
  position: relative;
  top: -5px;
  background: #eeeeff;
}
.pop a {
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
}

.pop a div {
  padding: 20px 30px;
}

.actualCount {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonbar {
  display: flex;
}

.countdown > p {
  margin-bottom: 20px;
}

@media screen and (max-width: 460px) {
  .countdown {
    margin-top: 2rem;
  }
  .buttonbar {
    flex-direction: column;
    align-items: stretch;
  }
  .App-header {
    margin-top: 100px;
  }
  .card-flip p {
    margin-top: 20px;
  }

  .buttonbar .countdown {
    margin-bottom: 136px;
    max-width: 223px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
